import "./style.css";
import * as THREE from "three";
import { loadModelActivos, modelBoundingBoxes, checkCollisions, updateBoundingBoxes } from './modelsActivos'; //
import { loadModelRigidos } from './modelsRigidos'; //
import { loadModelObras } from './modelsObras'; //
import { createLight } from './light';
import { paintingData } from './paintingData';
import { paintingInfo } from './paintingInfo';
import { setupEnvMap } from './envmap';
import { getAudioListener, setupAudio, playStepAudio, stopStepAudio, enableStepAudio } from './audio';
import { playLazyAudio } from './audio';
import gsap from "gsap";
import {
    PRELOADER,
    progressBar,
    intro,
    ingresarBtn,
    preloader,
    progressText,
    instruccionesDiv
} from "./preloader";
import { Capsule } from 'three/examples/jsm/math/Capsule.js';
import { Octree } from 'three/examples/jsm/math/Octree.js';
import { GUI } from 'dat.gui';
import 'dat.gui/build/dat.gui.css';
import { init, init as initPositional } from './positional.js';
import { JoyStick } from "./joystick.js";
import { RESIZE_SCREEN } from './resize';


// Scene
const scene = new THREE.Scene();
const textureLoader = new THREE.TextureLoader(PRELOADER);

// Camera
const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);
scene.add(camera);

camera.rotation.set(0, Math.PI, 0);  // Rotar la cámara 
//console.log("Posición inicial de la cámara:", camera.position);

//--------------------------------------------------------

let container = document.querySelector("#container"); 

// Renderer (WebGL)
const renderer = new THREE.WebGLRenderer({
    antialias: true,
    logarithmicDepthBuffer: true
});

renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5)); // Reduce a 1.5 si el dispositivo es lento
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0xefefef, 1);
renderer.shadowMap.type = THREE.PCFShadowMap;

//renderer.shadowMap.type = THREE.PCFSoftShadowMap;
document.body.appendChild(renderer.domElement);
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.shadowMap.enabled = true;
renderer.toneMappingExposure = 0.9;
renderer.outputEncoding = THREE.sRGBEncoding; //obras nitidas
const canvas = renderer.domElement;
renderer.physicallyCorrectLights = true

// responsive resize
RESIZE_SCREEN(camera, renderer);

//--------------------------------------------------------

let paintings = [];
let texts = [];
//const gui = new GUI();
let tip1 = document.querySelector("#tip1"); // usa el joystick
let tip2 = document.querySelector("#tip2"); // acercate a las obras
const keyCounter = document.getElementById('key-counter');


// book popup 
import { checkForBookPopup, box } from './booksPopup';
import { loadModelZonas } from "./modelsZonas.js";
scene.add(box); // box de referencia para popup books

setupEnvMap(renderer);

// Ejecuta la animación de aparición al cargar la página
document.addEventListener("DOMContentLoaded", () => {
    gsap.fromTo(
        "#intro",
        {
          //  y: window.innerHeight * 0.8, // Ajusta la posición de inicio un poco menos alta
            opacity: 0, // Comienza completamente transparente
            visibility: "visible",
            filter: "blur(10px)" // Añade desenfoque para una transición suave
        },
        {
          //  y: 0,
            opacity: 1,
            filter: "blur(0px)", // Elimina el desenfoque
            duration: 1, // Aumenta un poco la duración
            ease: "power2.out" // Suaviza la entrada para menos rebote
        }
    );
});


//--------------------------------------------------------

// Octree y el collider para la cámara (jugador)
const worldOctree = new Octree();
const playerCollider = {
    radius: 1,
    capsule: new Capsule(
        new THREE.Vector3(0, 0.5, 0),  // Inicio del capsule collider
        new THREE.Vector3(0, 1.75, 0),  // Fin del capsule collider
        0.5  // Radio del collider
    ),
};

// Asegurarse de que el playerCollider está correctamente definido antes de usarlo
//console.log("Posición inicial del playerCollider:", playerCollider);


//  // camera.position.set(2.7, 6, -10);  // Establecer la posición inicial 

//---------------------------------------------------------

const listener = getAudioListener();

if (!listener) {
  //  console.log('Error: listener no está disponible.');
    // return;
}

// Configurar audio y agregar el listener a la cámara
setupAudio(camera);

// Primero, define la función createPaintings
export const createPaintings = (scene, textureLoader, listener) => {
    const paintingMeshes = [];

    paintingData.forEach(painting => {
        const paintingMesh = painting.mesh;
        scene.add(paintingMesh);
        paintingMeshes.push(paintingMesh);
    });

    //console.log('Paintings Meshes:', paintingMeshes);
    return paintingMeshes;
};

// Click botón 'ingresarBtn' ###################################
//-----------------------------------------------------------
ingresarBtn.addEventListener("click", () => {
    // Iniciar el sistema de sonido posicional
    initPositional();
    const listener = getAudioListener();

    if (listener) {
        const audioContext = listener.context;
        if (audioContext && audioContext.state === 'suspended') {
            audioContext.resume().then(() => {
                //console.log('AudioContext resumed after user interaction.');
            });
        }
    }

    canvas.style.display = 'block'; // Mostrar el canvas de la escena

    // Habilitar el sonido de pasos
    enableStepAudio();

    // Crear pinturas y hacer otros setups
    paintings = createPaintings(scene, textureLoader, listener);

    // Animaciones de intro para ocultar UI ###################################
    // Animación de desaparición al hacer clic en ingresarBtn
    gsap.to("#intro", {
        opacity: 0,
        scale: 0.5, // Reduce el tamaño al desaparecer
        duration: 0.8,
        ease: "power3.in",
        onComplete: () => {
            intro.style.display = "none"; // Oculta completamente el elemento
        }
    });


    // Obtener la dirección hacia adelante de la cámara
    const forwardDirection = new THREE.Vector3();
    camera.getWorldDirection(forwardDirection);

    // Coloca la cámara 10 unidades hacia atrás en la dirección opuesta
    camera.position.x -= forwardDirection.x * 7;
    camera.position.y -= forwardDirection.y * 7;
    camera.position.z -= forwardDirection.z * 7;

    // Luego, anima hacia adelante 7 unidades
    gsap.to(camera.position, {
        x: camera.position.x + forwardDirection.x * 7,
        y: camera.position.y + forwardDirection.y * 7,
        z: camera.position.z + forwardDirection.z * 7,
        duration: 4,
        ease: "power3.out"
    });

    gsap.to("#key-counter", {
        opacity: 1,
        duration: 2,
        ease: "power3.out"
    });

    gsap.fromTo(
        ingresarBtn,
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: "power3.out" }
    );


    // Continuar con el resto de la configuración (sonido y tips)
    const soundTip = new THREE.Audio(listener);
    const audioLoader = new THREE.AudioLoader();

    audioLoader.load('static/audio/tip.mp3', (buffer) => {
        soundTip.setBuffer(buffer);
        soundTip.setVolume(0.3);
    });

    const timeline = gsap.timeline();


    // Timeline para animar Tip 1
    timeline.fromTo(
        tip1,
        { opacity: 0, scale: 0.5 }, // Empieza transparente y más pequeño
        {
            delay: 3,
            opacity: 1,
            scale: 1, // Escala hasta el tamaño original
            duration: 0.8,
            ease: "elastic.out(1, 0.3)", // Efecto rebote elástico
            onStart: function () {
                if (!soundTip.isPlaying) {
                    soundTip.play();
                }
            }
        }
    ).to(tip1, {
        opacity: 0,
        scale: 0.5, // Reduce el tamaño al desaparecer
        duration: 0.4,
        ease: "power3.in", // Disminuye rápidamente
        delay: 2
    });

    // Timeline para animar Tip 2
    timeline.fromTo(
        tip2,
        { opacity: 0, scale: 0.5 }, // Empieza transparente y más pequeño
        {
            opacity: 1,
            scale: 1, // Escala hasta el tamaño original
            duration: 0.8,
            ease: "elastic.out(1, 0.3)", // Efecto rebote elástico
            delay: 3,
            onStart: function () {
                if (!soundTip.isPlaying) {
                    soundTip.play();
                }
            }
        }
    ).to(tip2, {
        opacity: 0,
        scale: 0.5, // Reduce el tamaño al desaparecer
        duration: 0.4,
        ease: "power3.in",
        delay: 2
    });

});


// Inicializar el joystick --------------------
const joystick = new JoyStick({
    onMove: (forwardMovement, turnMovement) => {
        if (forwardMovement === 0 && turnMovement === 0) {
            // Detenemos el movimiento inmediatamente
            movement.forward = 0;
            movement.turn = 0;
        } else {
            // Si hay movimiento, seguimos usando interpolación
            movement.forward = THREE.MathUtils.lerp(movement.forward, forwardMovement, 0.1);
            movement.turn = THREE.MathUtils.lerp(movement.turn, turnMovement, 0.1);
        }
    },
    maxRadius: 40,
    game: { camera, renderer, scene },
});


export const movement = {
    forward: 0,
    turn: 0,
};


// Lógica de colisión extendida para incluir los modelos
function checkCollision(camera, modelBoundingBoxes) {
    const playerBoundingBox = new THREE.Box3();
    const cameraWorldPosition = new THREE.Vector3();
    camera.getWorldPosition(cameraWorldPosition);
    playerBoundingBox.setFromCenterAndSize(cameraWorldPosition, new THREE.Vector3(1, 0, 1));

    // Verificar colisiones con los modelos GLTF
    for (let i = 0; i < modelBoundingBoxes.length; i++) {
        if (playerBoundingBox.intersectsBox(modelBoundingBoxes[i])) {
            return true;
        }
    }

    return false;
}

//-----------------------------------------------------
const GRAVITY = 30;  // Factor de gravedad
let velocity = new THREE.Vector3(0, 0, 0);
const STAIR_THRESHOLD = 0.6;  // Umbral para detectar escaleras
let playerOnFloor = false;
const MAX_FALL_SPEED = -50;  // Limitar la velocidad de caída
const STEP_SOUND_THRESHOLD = 0.01;  // Umbral mínimo de movimiento para reproducir el sonido de pasos

function updateCamera(delta, camera) {
    const moveSpeed = delta * 2.0;
    const turnSpeed = delta * 1.0;
    const moveDirection = new THREE.Vector3();

    // Movimiento hacia adelante
    if (movement.forward !== 0) {
        moveDirection.z = -movement.forward * moveSpeed;
    }

    // Aplicar rotación de la cámara al movimiento
    moveDirection.applyQuaternion(camera.quaternion);
    camera.position.add(moveDirection);

    // Actualizar la posición del collider del jugador (cámara)
    playerCollider.capsule.start.copy(camera.position);
    playerCollider.capsule.end.copy(camera.position).y += 1.75;

    // Verificar colisiones con el Octree (suelo o modelos)
    const result = worldOctree.capsuleIntersect(playerCollider.capsule);

    if (result) {
        const normal = result.normal;
        camera.position.add(normal.multiplyScalar(result.depth));  // Mover la cámara fuera de la colisión
        velocity.y = 0;  // Detener la caída
        playerOnFloor = true;  // El jugador está sobre una superficie
    } else {
        playerOnFloor = false;  // No hay colisión, aplicar gravedad
    }

    // Aplicar gravedad solo si el jugador no está en el suelo
    if (!playerOnFloor) {
        // Aplicar la gravedad solo si no está sobre el suelo
        velocity.y = Math.max(velocity.y - GRAVITY * delta, MAX_FALL_SPEED);  // Limitar la velocidad de caída
        camera.position.y += velocity.y * delta;  // Aplicar movimiento vertical (gravedad)
    } else {
        velocity.y = 0;  // Resetear la velocidad vertical si el jugador está en el suelo
    }

    // Rotación de la cámara
    if (movement.turn !== 0) {
        camera.rotation.y -= movement.turn * turnSpeed;  // Girar la cámara según el joystick
    }

    // Verificar si el jugador se está moviendo lo suficiente para reproducir los pasos
    const currentVelocity = new THREE.Vector3(
        moveDirection.x,
        0,  // Ignorar el movimiento en el eje Y para el cálculo de la velocidad horizontal
        moveDirection.z
    ).length();  // Calcular la longitud del vector de movimiento solo en X y Z

    // Revisamos si el jugador está en movimiento horizontalmente o verticalmente
    if (currentVelocity > STEP_SOUND_THRESHOLD || Math.abs(velocity.y) > STEP_SOUND_THRESHOLD) {
        playStepAudio();  // Reproducir el sonido de los pasos si el jugador se está moviendo
    } else {
        stopStepAudio();  // Detener el sonido de los pasos si el jugador no se mueve
    }
}


//---------------------------------------------------------------

// Añadir luces antes de cargar el modelo
createLight(scene, camera);

// Cargar y configurar los componentes de la escena
loadModelActivos(scene, PRELOADER, camera);
loadModelZonas(scene, PRELOADER, camera);
loadModelRigidos(scene, PRELOADER).then((cRigidos) => {

    // Añadir el modelo a la escena
    scene.add(cRigidos);

    // Buscar el nodo 'piso-general'
    const floor = cRigidos.getObjectByName('piso-general');

    if (floor) {
        const floorPosition = floor.position;

        // Ajustar la posición de la cámara sobre el piso, elevándola 3 metros sobre el nodo del suelo
        // posicion actual de la camara ajustes aqui

        camera.position.set(floorPosition.x - 8.4, floorPosition.y + 3.5, floorPosition.z - 1.5);  // Ajusta la altura de la cámara 

       // console.log("Cámara posicionada sobre 'piso-general' en:", camera.position);

    } else {
        console.warn("Nodo 'piso-general' no encontrado en el modelo GLTF.");
    }
});


// Crear y configurar las pinturas una vez cargadas
loadModelObras(scene, PRELOADER).then(() => {
    // Llamar a paintingInfo solo después de que las pinturas se hayan cargado
    paintingInfo(paintings, camera);
});

// Actualizar otros elementos, como la información de las pinturas
paintingInfo(paintings, camera);


const clock = new THREE.Clock();

// =================================================
// animate function ================================

const animate = function () {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
    const delta = clock.getDelta();

    // Asegurarse de que las pinturas se hayan cargado antes de verificar proximidad
    if (paintings.length > 0) {
        paintingInfo(paintings, camera);  // Solo llama a paintingInfo si las pinturas ya están cargadas
    }

    // Actualizar las cajas de colisión con respecto a la posición actual de los nodos
    updateBoundingBoxes();

    const elapsedTime = clock.getElapsedTime();


    const playerBoundingBox = new THREE.Box3();
    const cameraWorldPosition = new THREE.Vector3();
    camera.getWorldPosition(cameraWorldPosition);
    playerBoundingBox.setFromCenterAndSize(cameraWorldPosition, new THREE.Vector3(1, 2, 1));

    // Verificar colisiones
    checkCollisions(playerCollider);
    // popup biblios con delay para evitar problemas
    setTimeout(() => {
        checkForBookPopup(camera);
    }, 300);  // Esperar 100ms antes de llamar


    // Aquí puedes manejar la colisión rígida
    if (modelBoundingBoxes.some(({ box }) => box.intersectsBox(playerBoundingBox))) {
        // Aquí detienes o ajustas la posición del jugador/cámara si hay colisión
        // Por ejemplo, puedes corregir la posición de la cámara si ha colisionado
        camera.position.set(
            cameraWorldPosition.x - delta, // Esto es solo un ejemplo, ajusta según tu lógica
            cameraWorldPosition.y,
            cameraWorldPosition.z
        );
    }

    // Actualizar otros elementos
    updateCamera(delta, camera, modelBoundingBoxes);

}; // =================================================


animate();

// Exportar después de inicializar
export { scene, camera, renderer, worldOctree, clock };
