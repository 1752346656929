import './style.css';
import * as THREE from 'three';

/**
 * Canvas change RESIZE
 */
export function RESIZE_SCREEN(camera, renderer, composer = null) {
    const SIZES = {
        width: window.innerWidth,
        height: window.innerHeight
    };

    window.addEventListener("resize", () => {
        SIZES.width = window.innerWidth;
        SIZES.height = window.innerHeight;

        camera.aspect = SIZES.width / SIZES.height;
        camera.updateProjectionMatrix();

        renderer.setSize(SIZES.width, SIZES.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        if (composer) {
            composer.setSize(SIZES.width, SIZES.height);
        }
    });
}
